import React, { FC } from 'react';
import { createBemHelper } from 'utils/BEM';
import './Icon.scss';

const BEM = createBemHelper('icon');

export interface IconProps {
  className: string;
  color?: string;
  link?: string;
}

export const Icon: FC<IconProps> = props => {
  const { className, color, link } = props;
  const style = {
    ...(color
      ? {
          color,
        }
      : {}),
  };
  return (
    <a
      style={{
        textDecoration: 'none',
        color: 'unset',
        boxShadow: 'none',
      }}
    >
      <i
        className={[BEM('', { clickable: !!link }), className].join(' ')}
        style={style}
        onClick={() => {
          if (link) {
            window.open(link, '_blank');
          }
        }}
      />
    </a>
  );
};
