import React, { FC } from 'react';

import { createBemHelper } from 'utils/BEM';
import './Button.scss';

const BEM = createBemHelper('button');

export interface ButtonProps {
  color?: 'primary' | 'accent-light' | 'accent-dark' | 'dark';
  size?: 'medium' | 'large';
  onClick: () => void;
}

export const Button: FC<ButtonProps> = props => {
  const { color = 'primary', size = 'medium', onClick } = props;

  return (
    <div
      className={BEM('', {
        primary: color === 'primary',
        dark: color === 'dark',
        'accent-dark': color === 'accent-dark',
        'accent-light': color === 'accent-light',
        medium: size === 'medium',
        large: size === 'large',
      })}
      onClick={onClick}
    >
      {props.children}
    </div>
  );
};
