import React, { FC } from 'react';
import { createBemHelper } from '../../utils/BEM';
import { getYear } from 'date-fns';

import SEO from '../../components/seo';

import './Page.scss';
import { SocialMediaBar } from 'components/SocialMediaBar/SocialMediaBar';
import { Icon } from 'components/Icon/Icon';
import { Button } from 'components/Button/Button';
import { useStaticQuery, graphql } from 'gatsby';
import { Navbar } from 'components/Navbar/Navbar';
import Media from 'react-media';

const BEM = createBemHelper('page');

export interface PageProps {
  title: string;
  onShowBooking: () => void;
}

export const Page: FC<PageProps> = props => {
  const data = useStaticQuery(graphql`
    query PageQuery {
      logoImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(height: 68, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logoImageOnly: file(relativePath: { eq: "logo-image-only.png" }) {
        childImageSharp {
          fixed(height: 68, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const { title, onShowBooking } = props;

  return (
    <div className={BEM()} id="page">
      <SEO title={title} />
      <div className={BEM('header')}>
        <Media
          queries={{
            small: '(max-width: 640px)',
            medium: '(max-width: 1024px)',
            large: '(min-width: 1025px)',
          }}
        >
          {matches => {
            const view = (function () {
              if (matches.small) {
                return 'small';
              }
              if (matches.medium) {
                return 'medium';
              }
              return 'large';
            })();

            return (
              <Navbar
                logoSrc={data.logoImageOnly.childImageSharp.fixed.src}
                onMakeBooking={onShowBooking}
                view={view}
              />
            );
          }}
        </Media>
      </div>
      <div className={BEM('main')} id="page-main">
        <div className={BEM('content-container')}>{props.children}</div>
        <footer className={BEM('footer-container')}>
          <div className={BEM('footer-middle')}>
            <SocialMediaBar />
            <div className={BEM('contact-details')} data-aos="fade-up" data-aos-once="true">
              <div className={BEM('contact-row')}>
                <i className={'ri-phone-line'} /> <a href="tel:+27843573926">+27 84 357 3926</a>
              </div>
              <div className={BEM('contact-row')}>
                <i className={'ri-mail-line'} /> <a href="mailto:info@inanortje.co.za">info@inanortje.co.za</a>
              </div>
              <div className={BEM('contact-row')}>
                <i className={'ri-time-line'} /> <span>Mon - Fri | 08:00 - 17:00</span>
              </div>
            </div>
            <div className={BEM('locations')}>
              <div className={BEM('location')}>
                <i className={'ri-map-pin-line'} /> Weltevreden Park, Johannesburg
              </div>
              <div className={BEM('location')}>
                <i className={'ri-map-pin-line'} /> Virtual Consultations via Zoom
              </div>
            </div>
            <div className={BEM('copyright')}>
              © {getYear(new Date())} Ina Nortjé | B.Sc. Dietetics. Dipl. Hosp Dietetics. Neuro / Biofeedback
              Practitioner
            </div>
            <div className={BEM('robotfish')}>
              Made with <Icon className={'ri-heart-fill'} color={'red'} /> by RobotFish
            </div>
          </div>
          <div className={BEM('footer-gutter')}></div>
        </footer>
      </div>
    </div>
  );
};
