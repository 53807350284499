import { Button } from 'components/Button/Button';
import { Link, navigate } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';

export interface NavbarProps {
  logoSrc: string;
  onMakeBooking: () => void;
  view: 'small' | 'medium' | 'large';
}

const links = [
  {
    text: 'Home',
    subtext: 'Go to the home page',
    url: '/',
    icon: 'ri-home-2-fill',
  },
  {
    text: 'Services',
    subtext: 'See how Ina can help you',
    url: '/services',
    icon: 'ri-file-list-line',
  },
];

export const Navbar: FC<NavbarProps> = props => {
  const { view } = props;

  return (
    <header className="bg-white flex mx-auto items-center w-full tw:w-full tw:px-16 lg:w-9/12 py-2 px-8">
      <div className={'flex justify-start w-full'}>
        <div className={'flex items-center'}>
          <div className={'flex items-center'}>
            <img
              className={'m-0 w-full h-full cursor-pointer'}
              src={props.logoSrc}
              onClick={() => {
                navigate('/');
              }}
            />
          </div>
          {view !== 'small' && (
            <div className={'ml-4'}>
              <div className={'logo-ina-nortje'}>Ina Nortjé</div>
              {view !== 'medium' && <div className={'logo-slogan'}>Dietician (RD) & Wellness Coach</div>}
            </div>
          )}
        </div>
      </div>
      <div className={'flex justify-end items-center'}>{appropriateMenu(props)}</div>
    </header>
  );
};

const appropriateMenu = (props: NavbarProps) => {
  const [smOpen, setSmOpen] = useState(false);

  useEffect(() => {
    if (smOpen) {
      document.getElementById('page-main')!.classList.add('page-modal-open');
      document.getElementById('page-main')!.addEventListener('mousedown', () => {
        setSmOpen(false);
      });
    } else {
      document.getElementById('page-main')!.classList.remove('page-modal-open');
    }
  }, [smOpen]);

  const { view, onMakeBooking } = props;
  if (view === 'large' || view === 'medium') {
    return fullMenu(onMakeBooking);
  }
  if (view === 'small') {
    return (
      <>
        <i
          onClick={() => {
            setSmOpen(!smOpen);
          }}
          className="ri-menu-line text-3xl cursor-pointer"
        ></i>
        {smOpen && (
          <div
            className={'w-full bg-white flex flex-col items-center'}
            style={{
              position: 'absolute',
              top: '80px',
              left: '0px',
              zIndex: 50,
              opacity: 1,
            }}
          >
            <div className={'flex flex-col items-start'}>
              {links.map(l => {
                return (
                  <div
                    className={'flex items-start mb-4 cursor-pointer hover:bg-gray-200 px-4 py-4 w-full'}
                    onClick={() => {
                      setSmOpen(false);
                      navigate(l.url);
                    }}
                  >
                    <i
                      className={`${l.icon} color-primary h-full`}
                      style={{ marginRight: '18px', fontSize: '24px' }}
                    ></i>
                    <div className={'flex flex-col h-full w-5/6 justify-center'}>
                      <div
                        className={'mb-1'}
                        style={{
                          fontSize: '18px',
                          lineHeight: '23px',
                          letterSpacing: '0.5px',
                          color: '#1D2251',
                          fontWeight: 'bold',
                        }}
                      >
                        {l.text}
                      </div>
                      <div
                        style={{
                          fontSize: '12px',
                          lineHeight: '14px',
                          letterSpacing: '0.2px',
                          color: '#1E255E',
                        }}
                      >
                        {l.subtext}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={'mx-auto mb-8'}>
              <Button onClick={onMakeBooking}>BOOK NOW</Button>
            </div>
          </div>
        )}
      </>
    );
  }
};

const fullMenu = (onMakeBooking: () => void) => {
  return interlace(
    [
      ...links.map(l => {
        return (
          <Link
            className={'full-menu-item'}
            to={l.url}
            style={{
              textDecoration: 'none',
              color: 'unset',
              boxShadow: 'none',
              letterSpacing: '2.1px',
              fontSize: '15px',
              lineHeight: '17px',
            }}
          >
            {l.text}
          </Link>
        );
      }),
      <span className={'ml-2'}>
        <Button onClick={onMakeBooking}>BOOK NOW</Button>
      </span>,
    ],
    spacer
  );
};

const spacer = (
  <div
    style={{
      width: '34px',
    }}
  />
);

export const interlace = <T, U>(list: T[], interlacer: U) => {
  const result: (T | U)[] = [];
  for (let i = 0; i < list.length; i++) {
    result.push(list[i]);
    if (i !== list.length - 1) {
      result.push(interlacer);
    }
  }
  return result;
};
