import React, { FC } from 'react';
import { createBemHelper } from 'utils/BEM';
import { Icon } from 'components/Icon/Icon';
import './SocialMediaBar.scss';

const BEM = createBemHelper('social-media-bar');

export const SocialMediaBar: FC = props => {
  return (
    <div className={BEM()}>
      <div>
        Connect with{' '}
        <span className={BEM('text')} style={{ fontWeight: 'bold' }}>
          Ina Nortjé
        </span>{' '}
        on social media
      </div>
      <div data-aos="fade-up" data-aos-once="true" className={BEM('icons')}>
        {/* <a className={'unset-link'} href="https://www.facebook.com/ina.nortje.wholeness.equilibrium/"></a> */}
        <Icon  className={'ri-facebook-fill'}  link={'https://www.facebook.com/ina.nortje.wholeness.equilibrium/'} />
        <Icon className={'ri-instagram-fill'} link={'https://instagram.com/ina_nortje_nutrition'} />
        <Icon className={'ri-linkedin-fill'} link={'https://www.linkedin.com/in/ina-nortj%C3%A9-537b6ba2/'} />
      </div>
    </div>
  );
};
