export interface IAB {
  id: string;
}

export interface ABHero extends IAB {
  heading: string;
  subheading: string;
}

export const heroList: ABHero[] = [
  {
    id: 'hero-001',
    heading: "Don't you just wish living healthy was easier?",
    subheading:
      'The decision to live healthy should not be a daunting one. Ina Nortjé uses over 35 years of experience to help you achieve your health goals with simple, easy-to-follow programs to suit your lifestyle.',
  },
  {
    id: 'hero-002',
    heading: 'Living healthy is easy, trust me!',
    subheading:
      'The decision to live healthy should not be a daunting one. Ina Nortjé uses over 35 years of experience to help you achieve your health goals with simple, easy-to-follow programs to suit your lifestyle.',
  },
];

export const randomHero = (): ABHero => {
  return sample(heroList);
};

export const sample = <T>(list: T[]): T => {
  return list[Math.floor(list.length * Math.random())];
};
